
import { Component, Vue } from 'vue-property-decorator'
import AddToilet from './AddToilet.vue'
import { TableItem } from '@/types/common.d'

@Component({
  name: 'List',
  components: { AddToilet }
})
export default class List extends Vue {
  private searchInfo: {projectName: string} = {
    projectName: ''
  }

  private tableData: { tr: Array<TableItem>; data: Array<{ totalAmount: string; onlineAmount: string }> } = {
    tr: [
      {
        label: '项目名称',
        prop: 'projectName',
        minWidth: '160',
        showOverflowTooltip: true
      },
      {
        label: '厕所数量',
        prop: 'toiletTotal',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '蹲位数量(男)',
        prop: 'manCubicleTotal',
        minWidth: '120'
      },
      {
        label: '蹲位数量(女)',
        prop: 'womanCubicleTotal',
        minWidth: '120'
      }
    ],
    data: []
  }

  private loading = false

  page = 1
  size = 10
  total = 0
  isAdd = false

  created (): void {
    this.getList()
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<{ totalAmount: string; onlineAmount: string }> }>(this.$apis.toilet.selectSumProjectList,
      {
        projectName: this.searchInfo.projectName,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'toiletDetailMap',
      params: { projectId: id }
    })
  }

  onSearch (): void {
    this.page = 1
    this.getList()
  }
}
